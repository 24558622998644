import {api} from '../api';
import {TOAST_MESSAGE_FAIL, TOAST_MESSAGE_SUCCESS} from './toast';

export const THROUGHPUT__FETCH_FAILURE = 'THROUGHPUT__FETCH_FAILURE';
export const THROUGHPUT__FETCH_SUCCESS = 'THROUGHPUT__FETCH_SUCCESS';

export const getThroughput = (tableType) => async (dispatch) => {
	try {
		const response = await api.get(`/throughput/dynamo`, {params: {tableType}});
		dispatch({
			type: THROUGHPUT__FETCH_SUCCESS,
			payload: {
				tableType,
				...response.data.throughput.Table.ProvisionedThroughput,
			},
		});
	} catch (error) {
		dispatch({type: THROUGHPUT__FETCH_FAILURE});
	}
};

export const updateThroughput = (data) => async (dispatch) => {
	try {
		const response = await api.post(`/throughput/dynamo`, data);
		if (response.data.OK) {
            dispatch({type: TOAST_MESSAGE_SUCCESS, payload: {message: 'Update Saved'}});
		} else {
            dispatch({type: TOAST_MESSAGE_FAIL, payload: {message: 'Update Failed'}});
		}
	} catch (error) {
        dispatch({type: TOAST_MESSAGE_FAIL, payload: {message: 'Update Failed'}});
	}
};
