import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';
import {Form, Button} from 'react-bootstrap';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import * as yup from 'yup';
import requireAuth from '../auth/requireAuth';
import {DataAdminRole} from '../util/entitlements';
import * as actions from '../../actions/throughput';

const Throughput = (props) => {
	const throughput = useSelector((state) => state.throughput.data);
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	const {getThroughput, updateThroughput} = props;

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			await getThroughput('PROCESSING');
			await getThroughput('DATAPOINTS');
			setIsLoading(false);
		};
		fetchData();
	}, [getThroughput]);

	const handleUpdate = async (values, tableType) => {
		if (window.confirm('Are you sure you want to update the throughput?')) {
			setIsUpdating(true);
			await updateThroughput({...values, tableType});
			setIsUpdating(false);
		}
	};

	const validationSchema = (tableType) => {
		return yup.object().shape({
			read: yup
				.number()
				.required('Read capacity is required.')
				.min(tableType === 'PROCESSING' ? 1 : 50, `Read capacity must be at least ${tableType === 'PROCESSING' ? 1 : 50}.`)
				.max(100, 'Read capacity must be at most 100.'),
			write: yup
				.number()
				.required('Write capacity is required.')
				.min(1, 'Write capacity must be at least 1.')
				.max(5000, 'Write capacity must be at most 5000.'),
		});
	};

	const renderForm = (tableType) => (
		<div>
			<h2>{tableType} Throughput Management</h2>
			<Formik
				initialValues={{
					read: throughput[tableType].readCapacityUnits,
					write: throughput[tableType].writeCapacityUnits,
				}}
				enableReinitialize={true}
				validationSchema={validationSchema(tableType)}
				onSubmit={(values) => handleUpdate(values, tableType)}
			>
				{({handleChange, handleBlur, values, isValid, dirty}) => (
					<FormikForm>
						<Form.Group controlId={`${tableType}-read`}>
							<Form.Label>Read Capacity</Form.Label>
							<Form.Control
								type="number"
								name="read"
								value={values.read}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Form.Text className="text-muted">
								{tableType === 'PROCESSING'
									? 'The read throughput range is from 1 to 100.'
									: 'The read throughput range is from 50 to 100.'}
							</Form.Text>
							<ErrorMessage component="div" className="text-danger" name="read" />
						</Form.Group>
						<Form.Group controlId={`${tableType}-write`}>
							<Form.Label>Write Capacity</Form.Label>
							<Form.Control
								type="number"
								name="write"
								value={values.write}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Form.Text className="text-muted">
								The write throughput range is from 1 to 5000.
							</Form.Text>
							<ErrorMessage component="div" className="text-danger" name="write" />
						</Form.Group>
						<Form.Group controlId={`${tableType}-decreases`}>
							<Form.Label>Number of Decreases Today</Form.Label>
							<Form.Control
								type="text"
								readOnly
								value={throughput[tableType].numberOfDecreasesToday}
							/>
							{throughput[tableType].numberOfDecreasesToday === 3 && (
								<Form.Text className="text-danger">
									This is the last throughput decrease allowed today, please double check that you understand the consequences of proceeding. This can be a very costly mistake if done improperly.
								</Form.Text>
							)}
						</Form.Group>
						<Button variant="secondary" onClick={() => getThroughput(tableType)}>
							Reset
						</Button>
						<Button variant="primary" type="submit" disabled={!isValid || !dirty}>
							Update
						</Button>
					</FormikForm>
				)}
			</Formik>
		</div>
	);

	if (isLoading || isUpdating) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{renderForm('PROCESSING')}
			{renderForm('DATAPOINTS')}
		</div>
	);
};

export default compose(
	connect(null, {...actions}),
	requireAuth(DataAdminRole)
)(Throughput);