import {
	THROUGHPUT__FETCH_FAILURE,
	THROUGHPUT__FETCH_SUCCESS,
} from '../actions/throughput';

const initialState = {
	data: {
		PROCESSING: {
			readCapacityUnits: '',
			writeCapacityUnits: '',
			numberOfDecreasesToday: 0,
		},
		DATAPOINTS: {
			readCapacityUnits: '',
			writeCapacityUnits: '',
			numberOfDecreasesToday: 0,
		},
	},
	status: '',
};

export default function throughput(state = initialState, action) {
	switch (action.type) {
		case THROUGHPUT__FETCH_SUCCESS:
			const { tableType, ReadCapacityUnits, WriteCapacityUnits, NumberOfDecreasesToday } = action.payload;
			const readCapacityUnits = Math.max(ReadCapacityUnits, 1);
			const writeCapacityUnits = Math.max(WriteCapacityUnits, 1);
			return {
				...state,
				data: {
					...state.data,
					[tableType]: {
						readCapacityUnits,
						writeCapacityUnits,
						numberOfDecreasesToday: NumberOfDecreasesToday,
					},
				},
			};
		case THROUGHPUT__FETCH_FAILURE:
			return {
				...state,
				status: 'failure',
			};
		default:
			return state;
	}
}
